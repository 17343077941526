.about_container {
  display: flex;
  height: 100%;
  padding: 7vh 7% 7vh 7%;
  width: 100%;
  flex-direction: column;
  gap: 4vw;
}

@media (max-width: 870px) {
  .about_container {

    padding: 1vh 7% 1vh 7%;

  }
}

.about_container>div {
  display: flex;
  gap: 0.5vw;
  flex-direction: column;
}

.about_container>div>h3 {
  margin: 0;
  font-family: "Drukwidecyr", sans-serif;
  font-weight: bold;
  color: #ffffff;
  font-size: 1.2vw;
}

.about_container>div>p {
  margin: 0;
  color: #797979;
  font-size: 0.9vw;
}