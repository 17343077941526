.feedback_block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 5vw 3vw auto;
  gap: 10px;
  height: 70vh;
  padding: 7vh 7% 2vh 7%;
  width: 100%;
}

@media (max-width: 870px) {
  .feedback_block {
   
    height: 24vh;
   
  }
}

.feedback_block__list_of_blocks_slider {
  display: flex;
  gap: 2vw;
}

.feedback_block__title {
  grid-column: 1 / 2;
  grid-row: 1 / 2;

  font-family: "Drukwidecyr", sans-serif;
  font-weight: bold;
  color: #ffffff;
  font-size: 3vw;
  letter-spacing: -0.2vw;
  line-height: 2.4vw;
}

.feedback_block__description {
  grid-column: 1 / 2;
  grid-row: 2 / 3;

  color: rgba(125, 125, 125, 1);
  font-size: 0.8vw;
}

.feedback_block__list_of_blocks {
  grid-column: 1 / 2;
  grid-row: 3 / 4;
  padding: 5vw 0 0 0;
  height: 9vw;
}

.feedback_block__right_block {
  grid-column: 2 / 3;
  grid-row: 1 / 4;
}

/* .slick-list {margin: 0 -10px;}
.slick-slide>div {padding: 0 10px;} */
.feedback_block__list_of_blocks_slider_block {
  background-color: #1b1e24;
  padding: 2vw;
  border-radius: 1vw;
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.feedback_block__list_of_blocks_slider_block-content > img {
  width: 4vw;
  border-radius: 0.8vw;
}

.feedback_block__list_of_blocks_slider_block-content > span {
  color: white;
}

.feedback_block__list_of_blocks_slider_block-content > p {
  color: #797979;
  font-size: 0.8vw;
  margin: 0;
}

.feedback_block__list_of_blocks_slider_block-content {
  display: flex;
  flex-direction: column;
}

.feedback_block__list_of_blocks_slider_block > div {
  display: flex;
  gap: 0.4vw;
}

.feedback_block__list_of_blocks_slider_block
  > div:not(.feedback_block__list_of_blocks_slider_block-content)
  > img {
  width: 1.2vw;
}

.feedback_block__list_of_blocks-button {
  color: #ffffff;
  padding: 1.5vw;
  background-color: #2d323a;
  border-radius: 2vw;
  font-size: 1vw;
  height: fit-content;
  width: max-content;
}

.lastSells_ontainer {
  background-color: #191c20;
  padding: 1.5vw;
  margin: 0 3.5vw;
  border-radius: 1vw;
}

.lastSells_ontainer > h2 {
  font-family: "Drukwidecyr", sans-serif;
  font-weight: bold;
  color: white;
  font-size: 2vw;
  margin-left: 1vw;
}

.lastSells_ontainer-items {
  display: flex;
  flex-wrap: wrap;
  height: 25vw;
  overflow: auto;
  padding: 1vw;
  column-gap: 0.4vw;
  row-gap: 0.6vw;
}
