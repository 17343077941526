.footer_container {
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding: 7vh 7% 2vh 7%;
  width: 100%;
  flex-direction: column;
  margin-top: 10vh;
  margin-bottom: 1vh;
}

@media (max-width: 870px) {
  .footer_container {
  
    padding: 1vh 7% 1vh 7%;
   
  }
}

.footer_container-blocks {
  display: flex;
  gap: 8vw;
  padding-bottom: 3vw;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

@media (max-width: 870px) {
  .footer_container-blocks {
    gap: 3vw;
   }
}

.footer_container-blocks > div {
  display: flex;
  flex-direction: column;
  gap: 1.7vw;
}

.footer_container-blocks > div >img {
 height: 30px;
}

@media (max-width: 870px) {
  .footer_container-blocks > div >img {
    height: 10px;
   }
}

.footer_container-blocks > div > h4 {
  color: white;
  margin: 0;
  margin-bottom: 1vw;
  font-size: 1.1vw;
}

.footer_container-blocks > div>a{
  color: #797979;
  font-size: 1vw;
}

.footer_container-blocks > div>a:hover{
  color: #acacac;
}

.footer_container > ul >li:first-child{
  list-style-type: none;
}

.footer_container > ul{
  margin-top: 3vw;
  display: flex;
  color: #797979;
  gap: 1.5vw;
  padding-inline-start: 0;
}

@media (max-width: 870px) {
  .footer_container > ul{
    gap: 8.5vw;
  }
}

.footer_container > ul >li>a{
  color: #797979;
  font-size: 1vw;
}
.footer_container > ul >li>a:hover{
  color: #acacac;
}

.all_rights_reserved{
  color: #797979;
  font-size: 1vw;
}