.item_container {
  width: 7vw;
  height: 9vw;
  background-color: #22262d;
  border-radius: 0.5vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5vw 0.5vw 1vw 0.5vw;
  position: relative;
  transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  z-index: 1;
}
.item_select{
  border: 2px solid #6EAEFA;
}
.item_container-unvaileble{

  border-radius: 1vw;
  width: 7vw;
  height: 9vw;
  background-color: #22262dab;
  position: absolute;
  top: 0;
  left: 0;
}

.item_container:hover {
  transform: scale(1.1); /* Используем transform вместо zoom */
  z-index: 999;
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.5); /* Добавлен box-shadow при hover */
}

.item_container-img {
  width: 4.5vw;
  align-self: center;
}

.item_container-title {
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  margin: 0;
  font-size: 0.6vw;

  white-space: nowrap;        /* Запрещает перенос текста на новую строку */
    overflow: hidden;           /* Скрывает содержимое, которое не помещается */
    text-overflow: ellipsis;    /* Добавляет многоточие вместо обрезанного текста */
    display: block;  
}

.item_container-price {
  color: white;
}

.item_container-color {
  position: absolute;
  height: 0.2vw;
  width: 4vw;
  bottom: 0;
  border-radius: 0.5vw 0.5vw 0 0;
  left: 23%;
}

.item_container-price > p {
  margin: 0;
  font-size: 0.85vw;
}

.item_container-howold_block {
  display: flex;
  gap: 0.5vw;
  align-items: center;
}

.item_container-howold_block >img{
  width: 0.8vw;
}

.item_container-howold_block-span {
  color: #797979;
  font-size: 0.55vw;
}

.item_container-sticker-float-bd {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item_container-sticker-float-bd > span {
  font-size: 0.5vw;
  color: rgba(255, 255, 255, 0.5);
}

.item_container-sticker-float-bd-stickers {
  display: flex;
}

.item_container-sticker-float-bd-stickers > img {
  width: 1.1vw;
  transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out; /* Добавлен переход для box-shadow */

}


.item_container-sticker-float-bd-stickers > img:hover {
  transform: scale(2.5); /* Используем transform вместо zoom */
  z-index: 999;
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.5); /* Добавлен box-shadow при hover */
}