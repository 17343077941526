.block-info-user {
  display: grid;
  grid-template:
    "avatar nick . countTransaction summaTransaction"
    "avatar balance tradeurl countTransaction summaTransaction"
    ". . email countTransaction summaTransaction";
  grid-template-columns: 5vw 5vw 1fr 12vw 12vw;
  grid-template-rows: 2vw auto auto;
  column-gap: 2vw;
  row-gap: 0.8vw;
  margin: 3vw 3vw 1vw;
  background-color: rgba(255, 255, 255, 0.03);
  border-radius: 1vw;
  padding: 2vw 3vw;
}

.block-info-user_avatar {
  grid-area: avatar;
  width: 5vw;
  border-radius: 100%;
}

.block-info-user_nick {
  grid-area: nick;
  color: white;
  font-size: 1.2vw;
  font-family: "Montserrat", sans-serif;
  align-content: space-evenly;
}

.block-info-user_countTransaction {
  width: 12vw;
  grid-area: countTransaction;
  background-color: rgba(255, 255, 255, 0.02);
  border-radius: 1vw;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.block-info-user_countTransaction>span {
  color: rgba(121, 121, 121, 1);
  font-size: 1vw;
}

.block-info-user_countTransaction>h2 {
  font-family: "Drukwidecyr", sans-serif;
  margin: 0;
  font-size: 1.6vw;
}

.block-info-user_summaTransaction {
  width: 12vw;
  grid-area: summaTransaction;
  background-color: rgba(255, 255, 255, 0.02);
  border-radius: 1vw;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.block-info-user_summaTransaction>span {
  color: rgba(121, 121, 121, 1);
  font-size: 1vw;
}

.block-info-user_summaTransaction>h2 {
  font-family: "Drukwidecyr", sans-serif;
  margin: 0;
  font-size: 1.6vw;
}

.block-info-user_summaTransaction>h2>span {
  font-family: "Drukwidecyr", sans-serif;
  color: rgba(110, 174, 250, 1);
}

.block-info-user_balance {
  grid-area: balance;

  color: rgba(121, 121, 121, 1);
  font-size: 1vw;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  align-content: space-evenly;
}

.block-info-user_tradeurl {
  grid-area: tradeurl;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  gap: 1vw;
}

.block-info-user_tradeurl>span {
  color: rgba(121, 121, 121, 1);
  font-size: 0.8vw;
  font-family: "Montserrat", sans-serif;
  width: 4vw;
}

.block-info-user_tradeurl>input {
  color: rgb(255 255 255);
  font-family: "Montserrat", sans-serif;
  background-color: transparent;
  border: 1px solid rgba(74, 74, 74, 1);
  padding: 0.7vw 1vw;
  font-size: 0.8vw;
  width: 65%;
}

.block-info-user_email {
  grid-area: email;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  gap: 1vw;
}

.block-info-user_email>span {
  color: rgba(121, 121, 121, 1);
  font-size: 0.8vw;
  font-family: "Montserrat", sans-serif;

  width: 4vw;
}

.block-info-user_email>input {
  color: rgb(255 255 255);
  font-family: "Montserrat", sans-serif;
  background-color: transparent;
  border: 1px solid rgba(74, 74, 74, 1);
  padding: 0.7vw 1vw;
  font-size: 0.8vw;
  width: 65%;
}

.block-info-user_buttons-select {
  margin: 1vw 3vw 1vw;
  background-color: rgba(255, 255, 255, 0.03);
  display: flex;
  border-radius: 0.5vw;
}

.block-info-user_buttons-select-active {
  color: rgba(110, 174, 250, 1);
  background-color: rgba(255, 255, 255, 0.1);
  width: 100%;
  padding: 1.1vh;
  border-radius: 0.5vw;
}

.block-info-user_buttons-select>button:not(.block-info-user_buttons-select-active) {
  color: rgba(110, 174, 250, 1);
  background-color: rgba(255, 255, 255, 0.03);
  width: 100%;
  padding: 1.1vh;
  border-radius: 0.5vw;
}

.block-info-user_transaction {
  background-color: rgba(255, 255, 255, 0.03);
  border-radius: 0.5vw;
  padding: 2vw 3vw;
  margin: 1vw 3vw 1vw;
}

.block-info-user_transaction-header {
  padding: 0 0 2vw 0;
}

.block-info-user_transaction-header>h2 {
  font-family: "Drukwidecyr", sans-serif;
  font-weight: 800;
  font-size: 1.2vw;
  margin: 0;
  color: white;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 15px;
  text-align: left;
}

th {
  background-color: rgba(40, 42, 44, 0.5);
  font-family: "Montserrat", sans-serif;
  color: #bbb;
  font-size: 0.7vw;
}

td {
  color: #fff;
}

td span {
  display: block;

  color: #888;
}

td>a {
  color: rgba(121, 121, 121, 1);
}

.status {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 4px;
  text-align: center;
  font-size: 0.8vw;
}

.status.success {
  background-color: rgba(65, 102, 94, 0.74);
  color: rgba(86, 174, 154, 1);
}

.status.create {
  background-color: rgba(102, 98, 65, 0.74);
  color: rgb(161, 174, 86);
}

.status.paymenting {
  background-color: rgba(65, 98, 102, 0.74);
  color: rgb(86, 168, 174);

}

.status.cancel {
  background-color: rgba(102, 65, 65, 0.74);
  color: rgb(174, 86, 86);
  
}

.balance {}

.balance.positive {
  color: rgba(86, 174, 154, 1);
}

.balance.negative {
  color: #dc3545;
}