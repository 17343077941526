.main-window-auth {
  width: 100%;
  height: calc(102vh - 90px);
  display: flex;
  padding: 3vw 7%;
  flex-direction: row;
  justify-content: space-between;
}

.main-window-auth__right_block {
  width: 36%;
  border-radius: 1vw;
  padding: 1vw;
  height: max-content;
  background-color: rgba(255, 255, 255, 0.04);
  display: flex;
  flex-direction: column;
}

.main-window-auth__right_block__selected {
  color: rgba(121, 121, 121, 1);
  font-size: 1vw;
  font-weight: 600;
}

.main-window-auth__right_block__selected-summa-block {
  display: flex;
  gap: 1vw;
}

.main-window-auth__right_block__selected-summa {
  color: white;
  font-size: 2vw;
  font-weight: 900;
  width: max-content;
}

.main-window-auth__right_block__selected-summa-rates {
  display: flex;
  flex-direction: column;
  color: rgba(121, 121, 121, 1);
  justify-content: center;
  font-size: 0.9vw;
}

.main-window-auth__right_block__selected>span {
  color: white;
}

.main-window-auth__left_block {
  display: flex;
  gap: 1vw;
  width: 63%;
  flex-direction: column;
}

.main-window-auth__left_block__filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.04);
  padding: 0.5vw;
  border-radius: 1vw;
  gap: 1vw;
}

.main-window-auth__left_block__filter-blocks-filter {
  display: flex;
  gap: 1vw;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.main-window-auth__left_block__filter-blocks-filter_refresh_spin {
  animation: spin 1s linear infinite;
}

.main-window-auth__left_block__filter-blocks-filter_refresh {
  background-color: rgba(121, 121, 121, 0.16);
  position: relative;
  width: 2.6vw;
  height: 2.6vw;

  border-radius: 0.7vw;
}

.main-window-auth__left_block__filter-blocks-filter_refresh>img {
  width: 0.8vw;
}

.main-window-auth__left_block__filter-blocks-filter_select {
  background-color: rgba(121, 121, 121, 0.16);
  position: relative;
  width: fit-content;
  text-align: center;
  padding: 0 1vw;
  font-family: "Montserrat", sans-serif;
  height: 2.6vw;
  border-radius: 0.7vw;
  color: rgb(185, 185, 185);
  font-size: 0.8vw;
}

.main-window-auth__left_block__items {
  background-color: rgba(255, 255, 255, 0.04);
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 0.53vw;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0.5vw;
  border-radius: 1vw;
  justify-content: flex-start;
  align-content: flex-start;
}

.main-window-auth__left_block__items_no-items {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.8vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.main-window-auth__left_block__filter-blocks-filter_serach {
  position: relative;
}

.main-window-auth__left_block__filter-blocks-filter_serach::before {
  content: "";
  position: absolute;
  left: 1vw;
  /* Позиционируем иконку слева */
  top: 42%;
  transform: translateY(-50%);
  left: 4%;
  width: 1vw;
  /* Ширина иконки */
  height: 1vw;
  /* Высота иконки */
  background-image: url("../../../assets/img/search-normal.svg");
  /* Замените на путь к вашей иконке */
  background-size: contain;
  background-repeat: no-repeat;
  pointer-events: none;
  /* Иконка не должна быть кликабельной */
}

.main-window-auth__left_block__filter-blocks-filter_serach>input {
  padding: 0.8vw 1.4vw 0.8vw 2.2vw;
}

.main-window-auth__left_block__filter-blocks-game {
  display: flex;
  gap: 0.5vw;
}

.main-window-auth__left_block__filter-blocks-game>div {
  padding: 0.3vw;
  border-radius: 0.4vw;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.main-window-auth__left_block__filter-blocks-game>div>img {
  width: 1.3vw;
}

.main-window-auth__left_block__tradeurl {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.04);
  padding: 1vw;
  border-radius: 1vw;
  gap: 1vw;
}

.main-window-auth__left_block__tradeurl a {
  text-align: center;
  color: white;
  width: 9vw;
  font-size: 1vw;
  padding: 1vw;
  background-color: rgba(121, 121, 121, 0.16);
  border-radius: 0.7vw;
}

.main-window-auth__left_block__tradeurl input {
  padding: 1vw 1.5vw 1vw 3vw;
  /* Добавляем отступ слева для иконки */
  width: 100%;
}

.main-window-auth__left_block__tradeurl::before {
  content: "";
  position: absolute;
  left: 1vw;
  /* Позиционируем иконку слева */
  top: 50%;
  transform: translateY(-50%);
  left: 4%;
  width: 1.2vw;
  /* Ширина иконки */
  height: 1.2vw;
  /* Высота иконки */
  background-image: url("../../../assets/img/search-normal.svg");
  /* Замените на путь к вашей иконке */
  background-size: contain;
  background-repeat: no-repeat;
  pointer-events: none;
  /* Иконка не должна быть кликабельной */
}

.main-window-auth__right_block__personal-info {
  display: flex;
  flex-wrap: wrap;
  gap: 1vw;
  margin-bottom: 2vw;
}

.main-window-auth__right_block__personal-info_submit {
  background-color: #6eaefa;
  width: 100%;
  color: white;
  padding: 0.8vw;
  font-size: 1vw;
  border-radius: 0.5vw;

}

.main-window-auth__right_block__personal-info_desc {
  font-size: 0.9vw;
  color: white;
  margin-bottom: 1vw;
}

.main-window-auth__right_block__selected-payment {
  display: flex;
  flex-wrap: wrap;
  gap: 1vw;

}

.main-window-auth__right_block__selected-payment>div {
  width: 8.9vw;
  background-color: #2d3540;
  padding: 0.7vw;
  border-radius: 0.7vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.main-window-auth__right_block__selected-payment>div>img {
  height: 1.7vw;
}

.main-window-auth__right_block__selected-payment-select {
  border: 2px solid #6eaefa;

}

.main-window-auth__right_block__selected-payment>div>span {
  position: absolute;
  color: #6a6a6a;
  font-size: 0.6vw;
  top: 0.1vw;
  right:  0.3vw;
}

.main-window-auth-trade {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.main-window-auth-trade>h2 {
  font-family: "Drukwidecyr", sans-serif;
  font-weight: bold;
  color: #ffffff;
  font-size: 2.2vw;
  text-align: center;
  letter-spacing: -0.2vw;
  line-height: 3vw;
}

.main-window-auth-trade_info {
  width: 55%;
  display: flex;
  justify-content: space-between;
  background-color: #191c20;
  margin-top: 5vw;
  border-radius: 1vw;
  padding: 1vw;
  flex-direction: column;
}

.main-window-auth-trade_items {
  display: flex;
  background-color: #191c20;
  margin-top: 5vw;
  border-radius: 1vw;
  flex-wrap: wrap;
  height: 25vw;
  overflow: auto;
  padding: 1vw;
  width: 100%;
  column-gap: 0.4vw;
  row-gap: 0.4vw;
  align-content: flex-start;
  justify-content: flex-start;
}

.main-window-auth-trade_info h2 {
  font-family: "Drukwidecyr", sans-serif;
  font-weight: bold;
  color: #ffffff;
  font-size: 1.3vw;
  letter-spacing: -0.1vw;
  line-height: 3vw;
  margin: 0;
}

.main-window-auth-trade_info-data {
  display: flex;
  gap: 1vw;
  padding: 1vw;
  flex-direction: column;
}

.main-window-auth-trade_info-data>div {
  display: flex;
  gap: 2vw
}

.main-window-auth-trade_info-data>div>div {
  display: flex;
  flex-direction: column;
  color: #c0c0c0;
  font-size: 0.75vw;
  gap: 0.6vw;
}


.main-window-auth-trade_info-data>div>div>a {
  color: white;
}

.main-window-auth-trade_info-button {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}

.main-window-auth-trade_info-button>a {
  text-decoration: none;
  background-color: #aaaaaa;
  padding: 0.6vw 1.3vw;
  border-radius: 0.5vw;
  font-size: 1vw;
  border: none;
  outline: none;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  gap: 0.5vw;
  align-items: center;
}

.main-window-auth-trade_info-button>a:first-child {
  background-color: #6EAEFA;
}

.main-window-auth-trade_info-button>a>img {
  width: 1.1vw;
  height: 1.1vw;
}

.main-window-auth-trade_info-button_container {
  padding: 1vw;
}

.main-window-auth-trade_container {
  display: flex;
  gap: 1vw;

}