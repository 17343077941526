@font-face {
    font-family: 'Gilroy';
    src: url('./Gilroy-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('./Gilroy-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('./Gilroy-ExtraBold.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Drukwidecyr';
    src: url('./druktextwidecyr-medium.otf');
    font-weight: bold;
    font-style: normal;
}
