.stats_block_container {
  display: flex;
  height: 50vh;
  padding: 7vh 7% 2vh 7%;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

@media (max-width: 870px) {
  .stats_block_container {
    height: 9vh;
    padding: 1vh 7% 1vh 7%;
  }
}

.stats_block_container > div > img {
  width: 9vw;
  position: absolute;
  top: 0;
}

.stats_block_container > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 17vw;
  height: 10vw;
  justify-content: flex-end;
}

.stats_block_container > div > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stats_block_container > div > div > h3 {
  color: white;
  font-size: 1.7vw;
  margin: 0;
}
.stats_block_container > div > div > p {
  color: #797979;
  margin: 0;
  font-size: 1vw;
}
