.status-progress-bar {
  width: 100%;
  height: 4vw;
  padding: 0 5%; /* 5vw padding on both sides */
  box-sizing: border-box;
  position: relative;
}

.status-progress-bar-track {
  width: 100%;
  height: 0.6vw;
  background-color: #2b2b3d;
  position: relative;
  margin-top: 10px; /* Adjust to center the circles */
}

.status-progress-bar-progress {
  height: 0.6vw;
  background-color: #6EAEFA;
  transition: width 0.5s ease;
}

.status-progress-bar-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  top: -0.7vw;

  position: absolute;
  text-align: center;
  transform: translateX(-50%); /* Center the circles horizontally */
}

.status-progress-bar-step-circle {
  width: 2vw;
  height: 2vw;
  border-radius: 50%;
  background-color: #2b2b3d;
  border: 4px solid #6EAEFA;
  transition: background-color 0.5s ease, border-color 0.5s ease;
  box-shadow: 0 0 15px rgba(110, 174, 250, 0.7); /* Add glow effect */
}

.status-progress-bar-step.active .status-progress-bar-step-circle {
  background-color: #6EAEFA;
  border-color: #6EAEFA;
  box-shadow: 0 0 20px rgba(110, 174, 250, 1); /* Intensified glow for active circle */
}

.status-progress-bar-step-label {
  color: #fff;
  font-size: 1vw;
}


