/* Header.css */

header {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr 220px;
  padding: 10px 7%;
  background-color: #191c1e;
  height: 70px;
  gap: 60px;
}

.logo img {
  height: 30px;
}
.logo {
  width: fit-content;
}

.comingsoon{
  background: #ffcd19;
    font-size: 0.8vw;
    border-radius: 0.3vw;
    width: auto;
    padding: 0.1vw 0.3vw;
    position: absolute;
    top: -0.5vw;
    right: -1vw;
}

.hamburger-menu {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 20px;
  cursor: pointer;
  z-index: 2;
}

.hamburger-menu .bar {
  width: 100%;
  height: 3px;
  background-color: #787878;
  transition: all 0.3s ease-in-out;
}

.hamburger-menu.open .bar:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger-menu.open .bar:nth-child(2) {
  opacity: 0;
}

.hamburger-menu.open .bar:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

.login__block {
  display: flex;
  gap: 15px;
  position: relative;
}

.login__block>div>div>span {
 color: white;
 padding: 0 0.5vw 0 0;
 font-size: 1vw;
 cursor: pointer;
}

.login__block > img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
.login__block > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}

.login__block > div > div {
  display: flex;
  gap: 10px;
  align-items: center;
}
.login__block-balance {
  color: white;
  font-weight: 700;
}

nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2vw;
}

nav div {
  display: flex;
  gap: 5px;
  align-items: center;
  position: relative;
}

nav div a {
  color: #797979;
  text-decoration: none;
  font-size: 14px;
}

nav div:hover a {
  color: #6eaefa;
  transition: 0.2s;
}

nav.open {
  z-index: 9;
  width: 230px;
  display: flex;
  position: absolute;
  border-radius: 0 0 5px 5px;
  top: 70px;
  right: 5px;
  background-color: #191c1efa;
  padding: 10px;
  box-shadow: 0 5px 10px rgb(0 0 0 / 41%);
  flex-direction: column;
  align-items: flex-start;
}

nav.open div {
  align-items: flex-start;
  gap: 10px;
}

.login {
  cursor: pointer;
  background-color: #6eaefa;
  border-radius: 8px;
  padding: 8px 17px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.login a {
  text-decoration: none;
  color: white;
}

@media (max-width: 1235px) {
  .login a {
    display: none;
  }
  header {
    grid-template-columns: auto 1fr 195px;
  }
  .login{
    width: 100px;
    justify-self: right;
  }
}

.social {
  display: flex;
  gap: 20px;
}

.social img {
  cursor: pointer;
}

@media (max-width: 870px) {

  .hamburger-menu {
    display: flex;
  }
  /* .login a {
    display: flex;
  } */
  header {
    grid-template-columns: auto 130px 30px;
  }

  .login {
    background-color: #6eaefa;
    border-radius: 8px;
    padding: 5px 15px;
  }

  nav {
    display: none;
    flex-direction: column;
  }
}



.dropdown-toggle {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.arrow {
  transition: transform 0.3s ease;
}

.arrow.up {
  transform: rotate(180deg);
}
