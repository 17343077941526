/* DropdownMenu.css */
.dropdown-container {
  position: relative;
}

.dropdown-toggle {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.arrow {
  transition: transform 0.3s ease;
}

.arrow.up {
  transform: rotate(180deg);
}

.dropdown-menu {
  position: absolute;
  /* background-color: #343538; */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.1vw;

  transform-origin: top;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 0;
  transform: scaleY(0);
  max-height: 0;
  overflow: hidden;
}

.dropdown-menu.open {
  top: 25px;
  right: 0;
  opacity: 1;
  transform: scaleY(1);
  max-height: 500px;
  /* достаточное значение для отображения всех элементов */
}

.dropdown-item {
  text-align: center;
  width: 100%;
  min-width: 100px;
  cursor: pointer;
}