@import "./assets/font/fonts.css";

* {
  font-family: "Gilroy", sans-serif;
  font-weight: 500;
  /* Medium */
  box-sizing: border-box;
}

/* Кастомный скроллбар для Webkit браузеров (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 0.6vw;
  /* Ширина скроллбара */
}

::-webkit-scrollbar-track {
  background: #191c1e;
  /* Цвет фона скроллбара */
}

::-webkit-scrollbar-thumb {
  background: #6eadfa7c;
  /* Цвет бегунка скроллбара */
}

::-webkit-scrollbar-thumb:hover {
  background: #4883aa;
  /* Цвет бегунка при наведении */
}

body {
  margin: 0;
  background-color: #0f1114;
}

.vertical-line {
  border-left: 2px solid rgba(255, 255, 255, 0.1);
}

.a {
  text-decoration: none;
  color: #666;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}

a:active,
/* активная/посещенная ссылка */
a:hover,
/* при наведении */
a {
  text-decoration: none;
  color: #6eaefa;
}

.link {
  width: auto;
  display: block;
  text-decoration: none;
  background-color: #343538;
  border-radius: 5px;
  border: none;
  outline: none;
  color: #ffffff;
  cursor: pointer;

  width: max-content;
  padding: 0.5vw 1.5vw;
}

.link:hover {
  
  background-color: #454649;
 
}

.danger {
  color: #ae3232;
}

input:focus,
textarea:focus,
select:focus {
  outline: none !important;
}

input {
  background-color: rgba(121, 121, 121, 0.16);
  outline: none;
  border: none;
  padding: 0.8vw 1vw;
  border-radius: 0.5vw;
  font-family: "Montserrat", sans-serif;
  color: white;
  font-size: 0.7vw;
}

.pagination a {
  color: white;
  cursor: pointer;
  font-size: 1vw;
}

.pagination {
  list-style-type: none;
  background-color: rgba(40, 42, 44, 0.5);
  display: flex;
  gap: 1vw;
  justify-content: center;
  align-items: center;
  padding: 0.6vw;
}

.pagination .active {
  background-color: rgba(110, 174, 250, 1);
  color: black;
  padding: 0.3vw;
  width: 1.7vw;
  text-align: center;
  height: 1.7vw;
  border-radius: 0.5vw;
}