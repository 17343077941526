.main-window {
  width: 100%;
  height: calc(100vw - 90px);
}

.main-window {
  padding: 10px 7%;
  width: 100%;
  height: calc(100vh - 90px);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 5vw 4vw auto auto;
  column-gap: 1vw;
  row-gap: 1vw;
}

@media (max-width: 870px) {
  .main-window {
    height: 30vh;
   
  }
}

.main-window-bg {
  background: url(../../../assets/img/bg.png) no-repeat center center;
  background-size: cover;
  grid-column: span 2; /* Make this element span both columns if needed */
}

.left-column-item {
  grid-column: 1 / 2; /* Place this item in the first column */
  
}

.centr-column-item {
  grid-column: 1 / 3;
  grid-row: 5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.centr-column-item > div {
  max-width: 30vw;
}

.centr-column-item > div > div {
  display: flex;
  align-items: center;
  gap: 0.8vw;
  font-size: 1.2vw;
  margin-bottom: 1vw;
}

.centr-column-item > div{
  font-size: 0.8vw;
}

.centr-column-item > div > div > img {
  width: 1.5vw;
}

.centr-column-item > div > div > span {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}

.centr-column-item-desc {
  color: #ffffff;
}

.centr-column-item-desc_gray {
  color: #797979;
}

.icon-main {
  position: relative;
  grid-column: 2; /* Place this item in the second column */
  grid-row: 2 / 3; /* Span all four rows */
}

.icon-main > img {
  z-index: -1;
  position: absolute;
  width: 49vw;
  top: -7vw;
}

.title {
  font-family: "Drukwidecyr", sans-serif;
    font-weight: bold;
    color: #ffffff;
    font-size: 3vw;
    letter-spacing: -0.2vw;
    line-height: 2.6vw;
}

.blue {
  color: rgba(110, 174, 250, 1);
}

.games {
  display: flex;
  gap: 0.6vw;
  align-self: flex-end;
}

.games > div {
  padding: 0.4vw 1vw;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  font-size: 0.6vw;
  border-radius: 0.3vw;
}

.desc {
  color: rgba(125, 125, 125, 1);
  font-size: 0.8vw;
}

.sell-but {
  background-color: #ffffff;
  border-radius: 1vw;
  font-size: 0.8vw;
  padding: 0.8vw 2vw;
  width: -moz-fit-content;
  width: fit-content;
  gap: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.sell-but > img {
  width: 1.5vw;
}

.icon-main_block-right {
  position: absolute;
  z-index: 1;
  display: flex;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 1.2vw;
  flex-direction: column;
  padding: 2vw;
  left: 25%;
  backdrop-filter: blur(5px);
  align-items: center;
  gap: 1.2vw;
}

.icon-main_block-right > img {
  width: 3vw;
  margin-top: 2vh;
}

.icon-main_block-right-title {
  font-size: 1.15vw;
  color: #ffffff;
}


.icon-main_block-right > .login {
  padding: 16px 25px;
  border-radius: 0.6vw;
}

@media (max-width: 870px) {
  .icon-main_block-right > .login {
    padding: 1vw;
    border-radius: 0.6vw;
    width: 15vw;
  }

  .icon-main_block-right > .login > img{
   width: 2.5vw;
  }
}

.icon-main_block-right-desc {
  font-size: 0.8vw;
  color: rgba(189, 189, 189, 1);
}

.icon-main_block-right-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
