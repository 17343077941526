.faq_window_container {
  display: grid;
  height: 100%;
  padding: 7vh 7% 7vh 7%;
  width: 100%;
  gap: 4vw;

  grid-template:
    "menu main"
    "help main";
  grid-template-columns: 19vw auto;
  grid-template-rows: 10vh auto;

}

.faq_window_container-menu {
  grid-area: menu;
  background-color: rgba(255, 255, 255, 0.03);
  width: 100%;
  border-radius: 0.7vw;
  height: fit-content;
}
.faq_window_container-menu>button.active{
  background-color: rgba(255, 255, 255, 0.05);
}
.faq_window_container-menu>button {
  text-align: left;
  width: 100%;
  background-color: transparent;
  font-size: 0.8vw;
  padding: 1vw 1.5vw;
  border-radius: 0.7vw;
  color: #797979;
}

.faq_window_container-menu>button:hover {
  background-color: rgba(255, 255, 255, 0.05);
}


.faq_window_container-main {
  grid-area: main;
  background-color: rgba(255, 255, 255, 0.03);
  padding: 2vw;
  border-radius: 0.7vw;
  height: 77vh;
  overflow: auto;
}

.faq_window_container-main>div>h2{
  color: white;
  font-family: "Drukwidecyr", sans-serif;
  margin: 0;
  font-size: 1.6vw;
  letter-spacing: -0.1vw;
  line-height: 2vw;
}

.faq_window_container-main>div>h3{
  color: white;
  font-family: "Drukwidecyr", sans-serif;
  margin: 0;
  font-size: 1.3vw;
  letter-spacing: -0.1vw;
  line-height: 2vw;
}

.faq_window_container-main p{
  color: white;
  font-size: 1vw;

}

.faq_window_container-help {
  display: flex;
  padding: 1.5vw;
  grid-area: help;
  gap: 1vw;
  border-radius: 0.7vw;
  background-color: rgba(255, 255, 255, 0.03);
  flex-direction: column;
  height: fit-content;
}

.faq_window_container-help>h3{
  color: white;
  font-family: "Drukwidecyr", sans-serif;
  margin: 0;
  font-size: 1.6vw;
  letter-spacing: -0.1vw;
  line-height: 2vw;
}

.faq_window_container-help>span{
  color: #797979;
  font-size: 0.8vw;
  line-height: 0.7vw;
}

.faq_window_container-help>button{
  background-color: #6EAEFA;
  color: white;
  font-size: 1vw;
  padding: 1vw 1.5vw;
  border-radius: 0.7vw;
}


.main-window-bg {
  background: url(../../../assets/img/bg.png) no-repeat center center;
  background-size: cover;
  grid-column: span 2; /* Make this element span both columns if needed */
}

