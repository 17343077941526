.faq_container {
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding: 7vh 7% 7vh 7%;
  width: 100%;
  flex-direction: column;
}

.faq_container > h2 {
  font-family: "Drukwidecyr", sans-serif;
  font-weight: bold;
  color: #ffffff;
  font-size: 3vw;
  letter-spacing: -0.2vw;
  line-height: 3vw;
}

.faq_container-blocks {
  display: flex;
  flex-wrap: wrap;
  row-gap: 4.5vw;
  justify-content: space-between;
}

.faq_container-blocks > div {
  position: relative;
}

.faq_container-blocks > div > h1 {
  position: absolute;
  top: 0;
  left: 0;
  background: radial-gradient(
    circle,
    rgba(121, 121, 121, 1) 0%,
    rgba(121, 121, 121, 0.3) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-family: "Drukwidecyr", sans-serif;
  font-weight: bold;
  font-size: 3vw;
  z-index: -1;
  margin: 0;
}

.faq_container-blocks > div > div {
  background-color: #15181d;
  width: 35vw;
  margin-left: 4.3vw;
  border-radius: 2vw;
}

.faq_container-blocks > div > div > h4 {
  border-radius: 2vw;
  background-color: #1b1e24;
  padding: 2vw;
  color: white;
  align-content: space-evenly;
  margin: 0;
  font-size: 1vw;
}

.faq_container-blocks > div > div > p {
  margin: 0;
  padding: 2vw;
  color: #797979;
  align-content: space-evenly;
  font-size: 0.9vw;
}
